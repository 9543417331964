.task {
  height: 100%;
  background-color: #141416 !important;
  display: flex;
  flex-direction: column;

  &-header {
    padding: 0 0.625rem;
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: #fff;

    &-title {
      font-weight: 500;
      font-size: 1.125rem;
    }

    &-detail {
      font-weight: 500;
      font-size: 0.875rem;
    }
  }

  &-main {
    flex: 1;
    padding: 0.625rem;

    .total__score {
      height: 5.5rem;
      margin-bottom: 0.625rem;
      padding: 0.625rem 0.625rem 0.625rem 0.9375rem;
      background: url('../../../assets/dinero/score_bg.png') no-repeat center center;
      background-size: 100% 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;

      &-detail {
        height: 100%;

        &-title {
          height: 1rem;
          margin-bottom: 1rem;
          font-size: 0.875rem;
          color: #fff;

          display: flex;
          align-items: center;
        }

        &-tips {
          width: 0.75rem;
          height: 0.75rem;
          margin-left: 0.3125rem;
        }

        &-num {
          line-height: 2rem;
          font-weight: 600;
          font-size: 1.75rem;
          color: #FFFFFF;
        }
      }

      &-exchange {
        width: 5.25rem;
        height: 1.625rem;
        border-radius: 1.5625rem;

        font-weight: 500;
        font-size: 0.5rem;
        color: #3A0BBD;
        text-transform: none;

        --color-primary: #fff;
        --color-primary-shade: #D3D3D3;
        --color-primary-shade-darker: #BEBEBE;

        &-icon {
          width: 0.8125rem;
          height: 0.75rem;
          margin-right: 0.1875rem;
        }

        &-txt {
          white-space: nowrap;
        }
      }
    }

    .daily__table {
      height: 8.375rem;
      margin-bottom: 0.625rem;
      padding-top: 0.625rem;
      box-sizing: border-box;
      background: url('../../../assets/dinero/daily_bg.png') no-repeat center center;
      background-size: 100% 100%;

      &-title {
        line-height: 1.375rem;;
        padding-left: 0.9375rem;
        margin-bottom: 0.625rem;
        font-weight: 500;
        font-size: 1rem;
        color: #FFFFFF;
      }

      &-list {
        width: 100%;
        padding: 0 4.625rem 0 0.9375rem;
        box-sizing: border-box;
        position: relative;
        overflow-x: auto;

        display: flex;
      }

      &-sublist {
        padding-top: 0.625rem;
        flex: 1;
        overflow-x: scroll;
        border-radius: 0.3125rem;

        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        &::-webkit-scrollbar {
          height: 0;
        }
      }

      &-item {
        width: 3.375rem;
        height: 4.375rem;
        padding: 0;
        margin-right: 0.375rem;
        border-radius: 0;
        position: relative;
        background: url('../../../assets/dinero/task_bg.png') no-repeat center center;
        background-size: 100% 100%;
        flex-shrink: 0;
        overflow: visible;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        text-transform: none;

        &.today {
          background-image: url('../../../assets/dinero/task_bg_today.png');

          .daily__table-item-score {
            color: #fff;
          }
        }

        &:active {
          background-color: transparent !important;
        }

        &:focus, &:hover {
          background-color: transparent !important;
        }

        &-complete {
          width: 0.75rem;
          height: 0.75rem;

          position: absolute;
          top: -0.125rem;
          right: -0.125rem;
        }

        &-title {
          margin-bottom: 0.625rem;
          font-weight: 500;
          font-size: 0.625rem;
          color: #3A0BBD;
          line-height: 1rem;
        }

        &-score {
          margin-bottom: 0.375rem;
          line-height: 1rem;
          font-weight: 500;
          font-size: 0.875rem;
          color: #6E1AFF;
        }

        &-gift {
          margin-bottom: 0.375rem;
          width: 0.875rem;
          height: 1rem;
        }

        &-info {
          font-weight: 400;
          font-size: 0.75rem;
          color: #6E1AFF;
          text-transform: initial;
          line-height: 1.5;

          &.active {
            color: #fff;
          }

          &.disabled {
            color: #a49ef8;
          }
        }

        &:last-of-type {
          margin: 0;
        }
      }

      &-target {
        position: absolute;
        top: 0;
        right: 0;
        width: 4.625rem;
        padding-top: 0.625rem;
        border-radius: 0px 0.9375rem 0.9375rem 0px;
        border-left: 1px solid #2B2D36;

        display: flex;
        justify-content: center;
      }
    }

    .task__list {
      display: flex;
      flex-direction: column;
      align-items: center;

      .task__item {
        width: 100%;
        height: auto;
        margin-bottom: 0.625rem;
        padding: 0.625rem;
        border-radius: 0.9375rem;
        border: 0.0625rem solid #2B2D36;

        --color-primary: #17191D;
        --color-primary-shade: #1f2229;
        --color-primary-shade-darker: #1f2229;

        display: flex;
        align-items: center;

        text-transform: none;

        &-icon {
          width: 2.875rem;
          height: 2.875rem;
          margin-right: 0.875rem;
        }

        &-main {
          padding-right: 2rem;
          flex: 1;
          text-align: left;
        }

        &-title {
          display: flex;

          &-content {
            flex: 1;
            line-height: 1.375rem;
            word-wrap: break-word;
            word-break: break-all;

            font-weight: 500;
            font-size: 1rem;
            color: #fff;
          }

          &-tips {
            width: 0.75rem;
            height: 0.75rem;
            margin: 0.3125rem 0 0 0.625rem;
          }
        }

        &-content {
          margin-top: 0.3125rem;
          padding-left: 0.875rem;
          position: relative;

          font-weight: 400;
          font-size: 0.875rem;
          color: #8E8E8E;

          &::before {
            content: '・';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        &-next {
          width: 1rem;
          height: 1rem;

          position: absolute;
          right: 0.625rem;
          bottom: 0.75rem;

          &.no-content {
            top: 50%;
            bottom: auto;
            transform: translateY(-50%);
          }
        }
      }

      .more-task {
        padding: 0.3125rem 0;
        position: relative;

        color: #8E8E8E;
        font-size: 0.875rem;

        &::before, &::after {
          content: " ";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 0.9375rem;
          height: 0.0625rem;
          background-color: #8E8E8E;
        }

        &::before {
          right: calc(100% + 0.625rem);
        }

        &::after {
          left: calc(100% + 0.625rem)
        }
      }
    }
  }
}
