.detail-header {
  height: var(--header-height);
  padding: 0.375rem 0.8125rem 0.5rem 0.8125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: #141416 ;
  position: relative;
  color: #fff;

  h3 {
    margin-bottom: 0;
    font-size: 1.25rem;
    font-weight: 500;
    margin-left: 1.375rem;
    margin-right: auto;
    user-select: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media (max-width: 600px) {
    padding: 0.5rem;
  }

  body.is-electron.is-macos & {
    -webkit-app-region: drag;
  }

  body.is-electron.is-macos #Main:not(.is-fullscreen) &:not(#TopicListHeader) {
    justify-content: space-between;
    padding: 0.5rem 0.5rem 0.5rem 4.5rem;
  }

  &-btn {
    position: absolute;
    left: 0.8125rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
.detail__list {
  height: calc(100% - var(--header-height));
  background-color: #141416;

  overflow-y: scroll;

  .detail__item {
    height: 4.6875rem;
    margin-left: 0.625rem;
    padding-right: 0.625rem;
    border-bottom: 0.0625rem solid #32384A;

    display: flex;
    align-items: center;

    &-icon {
      width: 2.875rem;
      height: 2.875rem;
      margin-right: 0.625rem;
    }

    &-main {
      flex: 1;
    }

    &-title, &-date {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-title {
      line-height: 1.5rem;
      font-weight: 500;
      font-size: 1rem;
      color: #FFFFFF;
      margin-bottom: 0.25rem;
    }

    &-date {
      line-height: 1rem;
      color: #8E8E8E;
      font-size: 0.875rem;
    }

    &-add {
      line-height: 1.5rem;
      font-weight: 600;
      font-size: 1rem;
      color: #3772FF;
      margin-bottom: 0.25rem;
      text-align: right;
    }

    &-total {
      line-height: 1rem;
      color: #8E8E8E;
      font-size: 0.875rem;
      text-align: right;
    }
  }
}

.detail__empty {
  height: calc(100% - var(--header-height));
  background-color: #141416;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .empty-img {
    width: 8.4375rem;
    height: 7.5rem;
    margin-bottom: 1.25rem;
  }

  .empty-title {
    margin-bottom: 0.6875rem;
    line-height: 1;

    font-weight: 400;
    font-size: 1.1875rem;
    color: #FFFFFF;
  }

  .empty-text {
    margin-bottom: 0.1875rem;
    line-height: 1;

    font-size: 0.75rem;
    color: #FFFFFF;
    opacity: 0.5;
  }
}
